import classnames from 'classnames';
import React from 'react';
import * as styles from './titleH2.module.scss';

type TitleH2Props = {
	className?: string
}

const TitleH2: React.FC<TitleH2Props> = ({ className, children }) => {
	return (
		<h2 className={classnames(className, styles.titleH2)}>{children}</h2>
	)
}

export default TitleH2